<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card flat rounded>
          <v-card-text>
            <v-row dense align="center">
              <v-col cols="12" md="3" lg="1">
                <v-btn tile block color="success" to="/display-groups/create"
                  >New group</v-btn
                >
              </v-col>
              <v-col cols="12" lg="3" md="12">
                <v-select
                  :loading="displayGroupsLoading"
                  :disabled="displayGroupsLoading"
                  @change="changeDisplayGroup"
                  v-model="selectedDisplayGroup"
                  :hide-details="true"
                  item-text="name"
                  item-value="id"
                  :items="displayGroups"
                  return-object
                  outlined
                  dense
                >
                </v-select>
              </v-col>
              <!-- <v-col cols="12" lg="1" sm="4">
                <v-btn
                  :disabled="displayGroupsLoading"
                  :loading="displayGroupsLoading"
                  @click="getDisplayGroups"
                  block
                  color="secondary"
                  tile
                  ><v-icon>mdi-refresh</v-icon>Refresh</v-btn
                >
              </v-col> -->
              <v-col cols="12" md="3" lg="1" v-if="selectedDisplayGroup">
                <v-btn
                  :disabled="displayGroupsLoading"
                  :to="{ path: `/display-groups/${selectedDisplayGroup.id}` }"
                  block
                  color="secondary"
                  tile
                  ><v-icon>mdi-cog</v-icon>Configure</v-btn
                >
              </v-col>
              <v-col cols="12" lg="1" sm="4" v-if="selectedDisplayGroup">
                <v-btn
                  :disabled="displayGroupsLoading"
                  :loading="displayGroupsLoading"
                  @click="getDisplayGroup(selectedDisplayGroup.id)"
                  block
                  color="secondary"
                  tile
                  ><v-icon>mdi-refresh</v-icon> Values</v-btn
                >
              </v-col>
              <v-col cols="12" lg="2" md="3" v-if="selectedDisplayGroup">
                <v-checkbox
                  dense
                  :disabled="!selectedDisplayGroup"
                  v-model="refreshAutomatically"
                  label="Refresh every 60 seconds"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col col="12" v-if="initialLoad">
        <v-sheet>
          <div class="text-center py-4">
            <v-progress-circular
              :size="250"
              :width="10"
              color="primary"
              indeterminate
            >
              <span>Loading display group...</span>
            </v-progress-circular>
          </div></v-sheet
        ></v-col
      >
      <v-col cols="12" v-if="!initialLoad && displayGroups.length === 0">
        <v-sheet>
          <div class="py-4">
            <div class="text-center">No display groups</div>
            <div>
              <v-btn
                class="mt-2"
                tile
                color="success"
                to="/display-groups/create"
                >New group</v-btn
              >
            </div>
          </div>
        </v-sheet>
      </v-col>
      <v-col cols="12" v-if="!initialLoad && displayGroups.length !== 0">
        <v-row dense v-if="displayGroupLoading && newGroupLoading">
          <v-col cols="12">
            <v-sheet>
              <div class="text-center py-4">
                <v-progress-circular
                  :size="250"
                  :width="10"
                  color="primary"
                  indeterminate
                >
                  <span>Loading display group...</span>
                </v-progress-circular>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row dense v-if="!newGroupLoading">
          <v-col
            cols="12"
            lg="2"
            md="3"
            sm="4"
            v-for="item in displayGroup.groupSensors"
            :key="item.id"
          >
            <v-card class="item">
              <v-card-title class="px-1" primary-title>
                <v-btn
                  color="grey darkned-4"
                  outlined
                  tile
                  small
                  block
                  :to="`/sensors/${item.id}`"
                >
                  <v-icon :class="[item.online ? 'green--text' : 'red--text']"
                    >mdi-broadcast</v-icon
                  ><span class="white--text">{{ item.name }}</span></v-btn
                >
              </v-card-title>
              <v-card-subtitle class="text-center white--text pb-0">{{
                item.sensorType
              }}</v-card-subtitle>
              <v-card-text class="px-1 pb-1">
                <v-row dense>
                  <v-col cols="12" v-if="item.online" class="chart-container">
                    <div
                      v-if="
                        item.sensorType.toLowerCase() === 'temperature sensor'
                      "
                    >
                      <Gauge
                        :value="item.lastValue"
                        :state="item.state"
                        :minRange="item.minDisplayRange"
                        :maxRange="item.maxDisplayRange"
                      />
                      <div class="text-center">
                        <v-row dense>
                          <v-col cols="3">{{ item.minDisplayRange }}</v-col>
                          <v-col cols="6">{{
                            item.lastValueTimestampUtc | formatDate
                          }}</v-col>
                          <v-col cols="3">{{ item.maxDisplayRange }}</v-col>
                        </v-row>
                      </div>
                    </div>
                    <div
                      v-if="item.sensorType.toLowerCase() === 'digital input'"
                    >
                      <DigitalInput
                        :value="item.lastValue"
                        :state="item.state"
                      />
                      <div class="text-center">
                        {{ item.lastValueTimestampUtc | formatDate }}
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    v-else
                    cols="12"
                    class="no-value-container grey darken-3"
                  >
                    Sensor offline
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Gauge from "../../components/charts/gague2.vue";

import { mapActions, mapState } from "vuex";
import store from "@/store";
import DigitalInput from "../../components/charts/digital-input.vue";

export default {
  beforeRouteLeave(to, from, next) {
    store.dispatch("displayGroups/resetState", null, { root: true });
    this.refreshAutomatically = false;
    next();
  },
  data() {
    return {
      selectedDisplayGroup: undefined,
      refreshAutomatically: false,
      timeout: undefined,
      initialLoad: true,
      newGroupLoading: false,
    };
  },
  async created() {
    this.initialLoad = true;
    await this.getDisplayGroups();
    await this.getDefaultDisplayGroup();
    this.initialLoad = false;
  },
  methods: {
    ...mapActions("displayGroups", [
      "getDisplayGroupsAsync",
      "getDisplayGroupAsync",
    ]),
    async getDisplayGroups() {
      await this.getDisplayGroupsAsync();
    },
    async getDefaultDisplayGroup() {
      //if (this.displayGroups.length === 0 || this.displayGroup) return;

      const defaultDisplayGroup = this.displayGroups.find(
        (d) => d.defaultGroup
      );

      if (defaultDisplayGroup) {
        this.selectedDisplayGroup = defaultDisplayGroup;
        await this.getDisplayGroup(this.selectedDisplayGroup.id);
      } else if (this.displayGroups.length > 0) {
        this.selectedDisplayGroup = this.displayGroups[0];
        await this.getDisplayGroup(this.selectedDisplayGroup.id);
      }
    },
    async getDisplayGroup(id) {
      await this.getDisplayGroupAsync(id);
    },
    async changeDisplayGroup(group) {
      this.newGroupLoading = true;
      this.selectedDisplayGroup = group;
      await this.getDisplayGroup(this.selectedDisplayGroup.id);
      this.newGroupLoading = false;
    },
  },
  watch: {
    refreshAutomatically() {
      if (this.refreshAutomatically && !this.timeout) {
        this.timeout = setInterval(async () => {
          await this.getDisplayGroup(this.selectedDisplayGroup.id);
        }, 10 * 1000);
      } else {
        clearInterval(this.timeout);
      }
    },
  },
  computed: {
    ...mapState("displayGroups", [
      "displayGroups",
      "displayGroup",
      "displayGroupsLoading",
      "displayGroupLoading",
    ]),
  },
  components: {
    Gauge,
    DigitalInput,
  },
};
</script>

<style scoped>
.chart-container {
  height: 180px;
}
.no-value-container {
  height: 180px;
  text-align: center;
  color: white;
  padding-top: 80px;
  font-size: 16px;
}
</style>
