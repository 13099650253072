import Vue from "vue";

export function success(title, text) {
  Vue.prototype.$notify({
    group: "success",
    title: `<div class="mx-2 text-h6">${title}</div>`,
    text: `<div class="mx-2">${text}</div>`,
  });
}

export function info(title, text) {
  Vue.prototype.$notify({
    group: "info",
    title: `<div class="mx-2 text-h6">${title}</div>`,
    text: `<div class="mx-2">${text}</div>`,
  });
}

export function warn(title, text) {
  Vue.prototype.$notify({
    group: "warn",
    title: `<div class="mx-2 text-h6">${title}</div>`,
    text: `<div class="mx-2">${text}</div>`,
  });
}

export function error(title, text) {
  Vue.prototype.$notify({
    group: "error",
    title: `<div class="mx-2 text-h6">${title}</div>`,
    text: `<div class="mx-2">${text}</div>`,
  });
}
