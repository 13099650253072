<template>
  <div class="text-center" style="margin-top: 30px">
    <div class="text-h2 font-weight-bold white--text">
      <div
        v-if="value !== null && value != undefined"
        :style="{ color: getColor() }"
      >
        <span v-if="value === 1"> On</span>
        <span v-if="value === 0">Off</span>
      </div>
      <div v-else>
        <span>Unknown</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      require: false,
      default: undefined,
    },
    state: {
      require: false,
      default: undefined,
    },
  },
  methods: {
    getColor() {
      const red = "#F44336";
      const yellow = "#FFEB3B";
      const blue = "#03A9F4";
      const green = "#8BC34A";

      if (!this.state) return green;

      if (this.state === "critical") return red;
      if (this.state === "warning") return yellow;
      if (this.state === "informational") return blue;

      return green;
    },
  },
  computed: {
    color() {
      return this.getColor();
    },
  },
};
</script>
