import { success, error } from "../../helpers/notifications";
import http from "../../services/http";

const intitialState = {
  user: {},
  userLoading: false,
};

const state = { ...intitialState };

const actions = {
  async getUserAsync({ commit }) {
    let user = localStorage.getItem("user");

    if (user) {
      user = JSON.parse(user);
    } else {
      try {
        commit("setUserLoading", true);
        const { data } = await http.get("user");
        user = data;
        localStorage.setItem("user", JSON.stringify(user));
      } catch (e) {
        error("Error", `Failed to update user information. ${e.message}`);
      } finally {
        commit("setUserLoading", false);
      }
    }
    commit("setUser", user);
  },
  async updateUserAsync({ commit }, userData) {
    try {
      commit("setUserLoading", true);
      await http.put("user", userData);
      localStorage.setItem("user", JSON.stringify(userData));
      commit("setUser", userData);
      success("Success", "User information successfully updated.");
    } catch (e) {
      error("Error", `Failed to update user information. ${e.message}`);
    } finally {
      commit("setUserLoading", false);
    }
  },
};

const mutations = {
  setUser: (state, user) => (state.user = user),
  setUserLoading: (state, loading) => (state.userLoading = loading),
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
