import { error, success } from "../../helpers/notifications";
import http from "../../services/http";

const initialState = {
  alarmRules: [],
  alarmRulesLoading: false,
  alarms: [],
  alarmsLoading: false,
  alarm: {
    sensorName: "",
    alarmName: "",
    alarmRule: {
      description: "",
      severity: "",
      conditions: [],
    },
    alarmStartUtc: undefined,
    alarmEndUtc: undefined,
    value: "",
  },
  alarmLoading: false,
};

const state = { ...initialState };

const actions = {
  async getAlarmRulesAsync({ commit }, id) {
    try {
      commit("setAlarmRulesLoading", true);
      const { data } = await http.get(`sensors/${id}/alarmrules`);
      commit("setAlarmRules", data);
    } catch (e) {
      commit("setAlarmRules", []);
      error("Error", "Failed to load alarm rules. " + e.message);
    } finally {
      commit("setAlarmRulesLoading", false);
    }
  },
  async updateAlarmRulesAsync({ commit }, { id, alarmRules }) {
    console.log(alarmRules);
    try {
      commit("setAlarmRulesLoading", true);
      for (let i = 0; i < alarmRules.length; i++) {
        console.log(alarmRules[i]);
        await http.put(
          `sensors/${id}/alarmrules/${alarmRules[i].id}`,
          alarmRules[i]
        );
      }
      success("Success", "Alarm rules updated.");
    } catch (e) {
      error("Error", "Failed to update alarm rules." + e.message);
    } finally {
      commit("setAlarmRulesLoading", false);
    }
  },
  async enableAlarmRuleGroupAsync({ commit, state }, { id, enable }) {
    try {
      commit("setAlarmRulesLoading", true);
      await http.put(`sensors/${id}/alarmrules/enable/${enable}`);
      const alarmRules = { ...state.alarmRules };
      alarmRules.enabled = enable;
      commit("setAlarmRules", alarmRules);
      success("Success", `Alarm group ${enable ? "enabled" : "disabled"}.`);
    } catch (e) {
      error("Error", `Failed to ${enable ? "enable" : "disable"} alarm group.`);
    } finally {
      commit("setAlarmRulesLoading", false);
    }
  },
  async getAlarmsAsync(
    { commit },
    {
      id = "",
      from = "",
      to = "",
      acknowledged = "",
      active = "",
      page = 1,
      pageSize = 200,
    }
  ) {
    try {
      commit("setAlarmsLoading", true);
      const { data } = await http.get(
        `alarms?sensorId=${id}&from=${from}&to=${to}&acknowledged=${acknowledged}&active=${active}&page=${page}&pageSize=${pageSize}`
      );
      commit("setAlarms", data);
    } catch (e) {
      commit("setAlarms", []);
      error("Error", `Failed to load alarms. ${e.message}`);
    } finally {
      commit("setAlarmsLoading", false);
    }
  },
  async getAlarmAsync({ commit }, id) {
    try {
      commit("setAlarmLoading", true);
      const { data } = await http.get(`alarms/${id}`);
      commit("setAlarm", data);
    } catch (e) {
      commit("setAlarm", undefined);
      error("Error", `Failed to load alarm. ${e.message}`);
    } finally {
      commit("setAlarmLoading", false);
    }
  },
  async ackAlarmAsync({ commit, state }, id) {
    try {
      commit("setAlarmLoading", true);
      await http.put(`alarms/${id}`);
      const alarm = state.alarm;
      alarm.acknowledged = true;
      commit("setAlarm", alarm);
      success("Success", `Alarm acknowledged.`);
    } catch (e) {
      error("Error", `Failed to acknowledge alarm. ${e.message}`);
    } finally {
      commit("setAlarmLoading", false);
    }
  },
  async ackAllAlarmsAsync({ commit }, sensorId) {
    try {
      commit("setAlarmLoading", true);
      await http.put(`alarms/ack-all?sensorId=${sensorId}`);
      success("Success", `Alarms acknowledged.`);
    } catch (e) {
      error("Error", `Failed to acknowledge alarm. ${e.message}`);
    } finally {
      commit("setAlarmLoading", false);
    }
  },
  resetState({ commit }) {
    commit("setInitialState");
  },
};

const mutations = {
  setAlarmRules: (state, alarmRuleDefinitions) =>
    (state.alarmRules = alarmRuleDefinitions),
  setAlarmRulesLoading: (state, loading) => (state.alarmRulesLoading = loading),
  setAlarms: (state, alarms) => (state.alarms = alarms),
  setAlarmsLoading: (state, loading) => (state.alarmsLoading = loading),
  setAlarm: (state, alarm) => (state.alarm = alarm),
  setAlarmLoading: (state, loading) => (state.alarmLoading = loading),
  setInitialState: (state) => Object.assign(state, initialState),
};

const getters = {
  alarmsFormatted: (state) => {
    return [
      ...state.alarms.filter((a) => !a.alarmEndUtc),
      ...state.alarms.filter((a) => a.alarmEndUtc),
    ];
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
