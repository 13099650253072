import moment from "moment";
import Vue from "vue";

Vue.filter("formatDate", function (date) {
  if (!date) return "";
  return moment.utc(date).local().format("MM-DD hh:mm:ss A");
});

Vue.filter("round", function (value) {
  return value.toFixed(2);
});
