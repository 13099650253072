import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import Auth from "../views/auth.vue";

import SensorList from "../views/sensors/sensor-list.vue";
import SensorRoot from "../views/sensors/sensor/sensor-root.vue";
import SensorReadings from "../views/sensors/sensor/sensor-readings.vue";
import SensorAlarms from "../views/sensors/sensor/sensor-alarms.vue";
import SensorAlarm from "../views/sensors/sensor/sensor-alarm.vue";
import SensorConfiguration from "../views/sensors/sensor/sensor-configuration.vue";
import SensorEvents from "../views/sensors/sensor/sensor-events.vue";

// import DisplayGroupList from "../views/display-groups/display-group-list.vue";
// import DisplayGroupRoot from "../views/display-groups/display-group/display-group-root.vue";
// import DisplayGroupReadings from "../views/display-groups/display-group/display-group-readings.vue";
// import DisplayGroupConfiguration from "../views/display-groups/display-group/display-group-configuration.vue";

import DisplayGroups from "../views/display-groups-legacy/display-groups.vue";
import DisplayEdit from "../views/display-groups-legacy/display-group-configuration.vue";
import DisplayCreate from "../views/display-groups-legacy/display-group-configuration.vue";

import DeviceList from "../views/devices/device-list.vue";
import DeviceRoot from "../views/devices/device/device-root.vue";
import DeviceConfiguration from "../views/devices/device/device-configuration.vue";
import DeviceEvents from "../views/devices/device/device-events.vue";

import Alarms from "../views/alarms/alarms-list.vue";
import Alarm from "../views/alarms/alarm.vue";

import User from "../views/user/user.vue";

const routes = [
  {
    path: "/",
    redirect: "sensors",
  },
  {
    path: "/sensors",
    name: "sensors",
    component: SensorList,
  },
  {
    path: "/sensors/:id",
    component: SensorRoot,
    props: true,
    children: [
      {
        path: "",
        component: SensorReadings,
      },
      {
        path: "alarms",
        component: SensorAlarms,
        children: [
          {
            path: ":alarmId",
            component: SensorAlarm,
            props: true,
          },
        ],
      },
      {
        path: "events",
        component: SensorEvents,
      },
      {
        path: "configuration",
        component: SensorConfiguration,
      },
    ],
  },
  {
    path: "/display-groups",
    component: DisplayGroups,
    props: true,
  },
  {
    path: "/display-groups/:id",
    component: DisplayEdit,
    props: true,
  },
  {
    path: "/display-groups/create",
    component: DisplayCreate,
    props: false,
  },
  {
    path: "/user",
    name: "user",
    component: User,
  },
  {
    path: "/devices",
    name: "devices",
    component: DeviceList,
  },
  {
    path: "/devices/:id",
    component: DeviceRoot,
    props: true,
    children: [
      {
        path: "",
        component: DeviceConfiguration,
      },
      {
        path: "events",
        component: DeviceEvents,
      },
    ],
  },
  {
    path: "/alarms",
    component: Alarms,
  },
  {
    path: "/alarms/:id",
    component: Alarm,
    props: true,
  },
  {
    path: "/auth",
    name: "auth",
    component: Auth,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const account = Vue.prototype.$msal.getAccount();
  if (!account && to.name !== "auth") {
    localStorage.setItem("authCallbackRoute", to.path);
    next({ name: "auth" });
  }
  next();
});

export default router;
