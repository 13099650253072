<template>
  <div>
    <v-card :loading="deviceLoading">
      <v-card-text>
        <v-row>
          <v-col cols="12" lg="4" md="6" sm="12">
            <v-form
              ref="deviceInformationFormvalid"
              v-model="deviceInformationFormvalid"
              lazy-validation
            >
              <v-text-field
                v-model="deviceCopy.name"
                :loading="deviceLoading"
                :disabled="deviceLoading"
                dense
                label="Name"
                :rules="rules.name"
                required
                filled
              ></v-text-field>
              <v-textarea
                class="mt-2"
                :loading="deviceLoading"
                :disabled="deviceLoading"
                dense
                :auto-grow="true"
                rows="5"
                label="Description"
                v-model="deviceCopy.description"
                hide-details
                filled
              ></v-textarea>
              <v-btn
                class="my-2"
                color="success"
                :loading="deviceLoading"
                :disabled="deviceLoading || !deviceInformationFormvalid"
                tile
                @click="updateDeviceInformation"
                >Update Device</v-btn
              ></v-form
            >
            <v-divider></v-divider>
            <span class="text-h6 white--text">Commands</span>
            <v-divider></v-divider>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">&nbsp;</th>
                    <th class="text-left">Command</th>
                    <th class="text-left">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in commands" :key="i">
                    <td class="pa-0">
                      <v-btn
                        v-if="item.status === 0"
                        tile
                        small
                        block
                        color="green"
                        :loading="deviceLoading"
                        :disabled="deviceLoading"
                        @click="invokeOrCancelCommand(item)"
                        >Invoke Command</v-btn
                      >
                      <v-btn
                        v-if="item.status === 1"
                        tile
                        small
                        block
                        color="red"
                        :loading="deviceLoading"
                        :disabled="deviceLoading"
                        @click="invokeOrCancelCommand(item)"
                        >Cancel Command</v-btn
                      >
                    </td>
                    <td>{{ item.command }}</td>
                    <td>
                      <span v-if="item.status === 0">Waiting invokation</span>
                      <span v-if="item.status === 1">Invoked</span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12" lg="8" md="6" sm="12">
            <div style="height: 450px">
              <JsonEditor
                :json="deviceCopy.settings"
                @change="(c) => (deviceCopy.settings = c)"
                @valid="(v) => (settingsValid = v)"
              />
            </div>
            <div v-if="!settingsValid" class="red">
              <span class="mx-2 text--white subtitle-1">Invalid JSON</span>
            </div>
            <v-btn
              class="my-1"
              tile
              :disabled="!settingsValid || deviceLoading"
              :loading="deviceLoading"
              color="success"
              @click="updateSettings"
              >Update Settings</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import JsonEditor from "../../../components/json-editor.vue";
import { mapActions, mapState } from "vuex";
export default {
  props: ["id"],
  data() {
    return {
      deviceCopy: {
        name: "",
        description: "",
        settings: {},
        systemCommands: {},
      },
      settingsValid: true,
      rules: {
        name: [(v) => !!v || "Device name is required"],
      },
      deviceInformationFormvalid: true,
    };
  },
  async created() {
    this.deviceCopy = { ...this.device };
  },
  methods: {
    ...mapActions("devices", [
      "getDeviceAsync",
      "invokeOrCancelCommandAsync",
      "updateDeviceInformationAsync",
      "updateDeviceSettingsAsync",
    ]),
    async invokeOrCancelCommand(command) {
      const status = command.status === 0 ? 1 : 0;
      await this.invokeOrCancelCommandAsync({
        id: this.id,
        command: command.command,
        status: status,
      });
      this.deviceCopy.systemCommands[command.command] = status;
    },
    async updateDeviceInformation() {
      await this.updateDeviceInformationAsync({
        id: this.id,
        deviceInformation: this.deviceCopy,
      });
    },
    async updateSettings() {
      await this.updateDeviceSettingsAsync({
        id: this.id,
        settings: this.deviceCopy.settings,
      });
    },
  },
  computed: {
    ...mapState("devices", ["device", "deviceLoading"]),
    commands() {
      const keys = Object.keys(this.deviceCopy.systemCommands);
      return keys.map((k) => ({
        command: k,
        status: this.deviceCopy.systemCommands[k],
      }));
    },
  },
  components: {
    JsonEditor,
  },
};
</script>
