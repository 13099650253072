<template>
  <div style="height: 98vh">
    <v-row align="center" justify="center" style="height: 100%">
      <v-col cols="12" md="4" class="text-center">
        <span class="text-h5 text--white" v-if="authenticating"
          >Authenticating...</span
        >
        <v-btn
          @click="authenticate()"
          large
          block
          outlined
          tile
          text
          :loading="authenticating"
          :disabled="authenticating"
          class="mt-2 light-blue darken-3"
        >
          <template v-slot:default>
            <v-icon large class="">mdi-login-variant</v-icon>
            <span>Authenticate</span>
          </template>
        </v-btn>
        <div v-if="authError" class="mt-2 text-center">
          <div>
            <span class="red--text">Authentication Error. </span>{{ authError }}
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { error } from "../helpers/notifications";
import { mapActions, mapState } from "vuex";

export default {
  name: "auth",
  data() {
    return {
      authenticating: false,
      authError: undefined,
    };
  },
  created() {
    if (this.$msal.getAccount()) {
      this.navigateToPath("sensors");
    }
  },
  methods: {
    ...mapActions("user", ["getUserAsync"]),
    ...mapActions("tenant", ["getTenantAsync"]),
    async authenticate() {
      this.authError = "";

      this.authenticating = true;

      let loginSuccessfull = false;

      try {
        await this.$msal.login();
        loginSuccessfull = true;
      } catch (e) {
        this.authenticating = false;
        loginSuccessfull = false;
        error("Error", `Authentication failed. ${e.errorMessage}`);
        this.authError = e.errorMessage;
      }

      if (loginSuccessfull) {
        let tenantAuthoized = false;

        try {
          await this.getTenantAsync();
          tenantAuthoized = true;
        } catch (e) {
          console.log("*******", e);

          this.authenticating = false;
          tenantAuthoized = false;

          // const statusCode = e.response.status;

          // if (statusCode >= 400 && statusCode < 500) {
          //   this.authError =
          //     "Tenant not authorized. Contact system administrator.";
          // } else if (statusCode >= 500 && statusCode < 600) {
          //   error("Error", `System failure. ${e.errorMessage}`);
          //   this.authError = `System failure. ${e.errorMessage}`;
          // }

          await this.forceSignOut();
        }

        let userAuthorized = false;

        if (tenantAuthoized) {
          try {
            await this.getUserAsync();
            userAuthorized = true;
          } catch (e) {
            error("Error", `Failed to load user. ${e}`);
            this.authenticating = false;
            userAuthorized = false;

            await this.forceSignOut();
          }
        }

        if (loginSuccessfull && tenantAuthoized && userAuthorized) {
          const authCallbackRoute = localStorage.getItem("authCallbackRoute");
          if (authCallbackRoute) {
            this.$router.push({ path: authCallbackRoute });
            localStorage.removeItem("authCallbackRoute");
          } else {
            this.$router.push({ path: "/display-groups" });
          }
        }
      }
    },
    navigateToPath(path) {
      this.$router.push({ path });
    },
    async forceSignOut() {
      alert("Tenant is not authorized. Forcing logout.");
      localStorage.removeItem("user");
      localStorage.removeItem("tenant");
      localStorage.removeItem("tenantFilter");
      await this.$msal.logout();
    },
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("tenant", ["tenant"]),
  },
};
</script>
