import * as msal from "msal";

export default class MSAL {
  constructor() {
    this.config = {
      auth: {
        clientId: process.env.VUE_APP_AUTH_CLIENT_ID,
        authority: process.env.VUE_APP_AUTH_AUTHORITY,
        redirectUri: window.location.origin + "/auth",
      },
    };
    this.msalInstance = new msal.UserAgentApplication(this.config);
    this.msalInstance.handleRedirectCallback(() => {});

    this.scopes = {
      scopes: ["api://0b6b0b44-71f3-45a5-8c0c-7757ebed3543/access.api"],
    };
  }

  async login() {
    if (this.msalInstance.getAccount()) {
      const response = await this.msalInstance.acquireTokenSilent({
        scopes: ["api://0b6b0b44-71f3-45a5-8c0c-7757ebed3543/access.api"],
        prompt: "select_account",
      });
      return response.accessToken;
    } else {
      await this.msalInstance.loginPopup(this.scopes);
    }
  }

  logout() {
    this.msalInstance.logout();
  }

  getAccount() {
    return this.msalInstance.getAccount();
  }

  async acquireToken() {
    try {
      const response = await this.msalInstance.acquireTokenSilent(this.scopes);
      return response.accessToken;
    } catch (error) {
      if (error instanceof msal.InteractionRequiredAuthError) {
        const response = await this.msalInstance.acquireTokenPopup(this.scopes);
        return response.accessToken;
      } else {
        console.warn(error);
      }
    }
  }
}
