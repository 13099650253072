<template>
  <v-row>
    <v-col col="12">
      <v-data-table
        disable-sort
        :items-per-page="200"
        :headers="headers"
        :items="devices"
        :loading="devicesLoading"
        @click:row="navigateToDevice"
        :loading-text="''"
      >
        <template v-slot:item.online="{ item }">
          <div v-if="item.online">
            <v-icon color="green"> mdi-broadcast </v-icon>
            Online
          </div>
          <div v-if="!item.online">
            <v-icon color="red"> mdi-broadcast </v-icon>
            Offline
          </div>
        </template>
        <template v-slot:item.statusLastUpdatedUtc="{ item }">
          {{ item.statusLastUpdatedUtc | formatDate }}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      headers: [
        { text: "Status", width: 150, value: "online" },
        { text: "Name", value: "name" },
        { text: "Status update", value: "statusLastUpdatedUtc" },
      ],
    };
  },
  async created() {
    await this.getDevicesAsync();
  },
  methods: {
    ...mapActions("devices", ["getDevicesAsync"]),
    navigateToDevice(device) {
      this.$router.push({
        path: `devices/${device.id}`,
      });
    },
  },
  computed: {
    ...mapState("devices", ["devices", "devicesLoading"]),
  },
};
</script>
