var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._v(" "+_vm._s(_vm.alarmsFormatted.length)+" "),_c('v-row',[(!_vm.$route.params.alarmId)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"disable-sort":"","items":_vm.alarmsFormatted,"headers":_vm.headers,"loading":_vm.alarmsLoading,"loading-text":'',"no-data-text":'No alarms',"height":'65vh',"items-per-page":200,"hide-default-footer":""},on:{"click:row":_vm.navigateToAlarm},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"pa-2",attrs:{"dense":"","align":"center"}},[_c('v-col',{attrs:{"cols":"12","lg":"1","md":"2","sm":"4"}},[_c('v-btn',{attrs:{"disabled":_vm.alarmsLoading,"block":"","color":"success","tile":""},on:{"click":_vm.getAlarms}},[_vm._v("Refresh")])],1),_c('v-col',{attrs:{"cols":"12","lg":"1","md":"3","sm":"4"}},[_c('v-select',{attrs:{"disabled":_vm.alarmsLoading,"hide-details":true,"items":_vm.activeFilters,"item-text":"text","item-value":"value","label":"Alarm state"},model:{value:(_vm.activeFilterSelected),callback:function ($$v) {_vm.activeFilterSelected=$$v},expression:"activeFilterSelected"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"1","md":"3","sm":"4"}},[_c('v-select',{attrs:{"disabled":_vm.alarmsLoading,"hide-details":true,"items":_vm.acknowledgedFilters,"item-text":"text","item-value":"value","label":"Acknowledged"},model:{value:(_vm.acknowledgedFilterSelected),callback:function ($$v) {_vm.acknowledgedFilterSelected=$$v},expression:"acknowledgedFilterSelected"}})],1),_c('v-col',{attrs:{"cols":"12","offset-lg":"7","sm":"4","md":"4","lg":"2"}},[_c('v-btn',{staticClass:"float-right",attrs:{"disabled":_vm.alarmsLoading,"color":"success","tile":"","block":""},on:{"click":_vm.acknowledgeAll}},[_vm._v("Acknowledge All")])],1)],1)]},proxy:true},{key:"item.acknowledged",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.acknowledged ? "Acknowledged" : "Unacknowledged")+" ")]}},{key:"item.severity",fn:function(ref){
var item = ref.item;
return [_c('Severity',{attrs:{"severity":item.severity}})]}},{key:"item.sensorName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.sensorName)+" ")]}},{key:"item.alarmStartUtc",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.alarmStartUtc))+" "),(item.alarmEndUtc)?_c('span',[_vm._v("- "+_vm._s(_vm._f("formatDate")(item.alarmEndUtc)))]):_c('span',[_vm._v("- Active")])]}},{key:"footer",fn:function(){return [_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"my-2 ml-4",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"outlined":"","tile":"","color":"white","disabled":_vm.page < 2},on:{"click":function($event){(_vm.page = _vm.page - 1), _vm.getAlarms()}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('span',{staticClass:"mx-4 caption"},[_vm._v("Page "+_vm._s(_vm.page)+" ")]),_c('v-btn',{attrs:{"disabled":_vm.alarmsFormatted.length === 0 || _vm.alarmsLoading,"outlined":"","tile":"","color":"white"},on:{"click":function($event){(_vm.page = _vm.page + 1), _vm.getAlarms()}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],1)]},proxy:true}],null,false,3017698815)})],1):_vm._e(),(_vm.$route.params.alarmId)?_c('v-col',{attrs:{"cols":"12"}},[_c('router-view')],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }