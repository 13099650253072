<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" lg="8">
        <v-card :loading="alarmLoading">
          <!-- <div class="text-h5 mx-4 pt-2">Alarm Information</div> -->
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12" lg="5">
                <div class="text-h6 pt-2 white--text">Alarm Information</div>
                <v-divider class="mb-2"></v-divider>
                <v-text-field
                  :loading="alarmLoading"
                  label="Sensor"
                  :value="alarm.sensorName"
                  readonly
                ></v-text-field>
                <v-text-field
                  :loading="alarmLoading"
                  label="Alarm Name"
                  :value="alarm.alarmName"
                  readonly
                ></v-text-field>
                <v-text-field
                  :loading="alarmLoading"
                  label="Description"
                  :value="alarm.alarmRule.description"
                  readonly
                ></v-text-field>
                <v-text-field
                  :loading="alarmLoading"
                  label="Severity"
                  :value="alarm.alarmRule.severity"
                  readonly
                >
                  <template v-slot:prepend>
                    <Severity
                      :caption="false"
                      :severity="alarm.alarmRule.severity"
                    ></Severity>
                  </template>
                </v-text-field>
                <v-text-field
                  :loading="alarmLoading"
                  label="Start"
                  :value="alarm.alarmStartUtc | formatDate"
                  readonly
                ></v-text-field>
                <v-text-field
                  :loading="alarmLoading"
                  v-if="alarm.alarmEndUtc"
                  label="End"
                  :value="alarm.alarmEndUtc | formatDate"
                  readonly
                ></v-text-field>
                <v-text-field
                  :loading="alarmLoading"
                  v-else
                  label="End"
                  :value="'Alarm Active'"
                  readonly
                ></v-text-field>
                <v-text-field
                  :loading="alarmLoading"
                  label="Trigger value"
                  :value="alarm.value"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" lg="7">
                <div class="text-h6 pt-2 white--text">Alarm Definition</div>
                <v-divider class="mb-2"></v-divider>
                <v-row dense>
                  <v-col
                    cols="12"
                    v-for="(condition, i) in alarm.alarmRule.conditions"
                    :key="i"
                  >
                    <v-row dense align="center">
                      <v-col cols="12" md="2">Reading value</v-col>
                      <v-col cols="12" md="5"
                        ><v-select
                          readonly
                          v-model="condition.operator"
                          :hide-details="true"
                          item-text="text"
                          item-value="value"
                          :items="operators"
                          outlined
                          dense
                        >
                        </v-select
                      ></v-col>
                      <v-col cols="12" md="5">
                        <v-text-field
                          v-model.number="condition.threshold"
                          readonly
                          type="number"
                          :persistent-hint="false"
                          :hide-details="true"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              outlined
              color="white"
              tile
              :to="`/alarms?sensor=${alarm.sensorId}`"
            >
              <v-icon>mdi-arrow-left </v-icon>
              <span>{{ alarm.sensorName }} Alarms</span></v-btn
            >
            <v-btn
              color="success"
              :disabled="alarmLoading"
              tile
              v-if="!alarm.acknowledged"
              @click="ackAlarmAsync(id)"
              >Acknowledge</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Severity from "../../components/severity.vue";
import { mapActions, mapState } from "vuex";
import store from "@/store";

export default {
  props: ["id"],
  beforeRouteEnter(to, from, next) {
    store.dispatch("alarms/resetState", null, { root: true });
    next();
  },
  data() {
    return {
      operators: [
        { value: ">", text: "Greater than" },
        { value: ">=", text: "Greater or equals to" },
        { value: "===", text: "Equals" },
        { value: "!==", text: "Not Equals" },
        { value: "<", text: "Less than" },
        { value: "<=", text: "Less than or equals to" },
      ],
    };
  },
  async created() {
    await this.getAlarmAsync(this.id);
  },
  methods: {
    ...mapActions("alarms", ["getAlarmAsync", "ackAlarmAsync"]),
  },
  computed: {
    ...mapState("alarms", ["alarm", "alarmLoading"]),
  },
  components: {
    Severity,
  },
};
</script>
