import { error } from "../../helpers/notifications";
import http from "../../services/http";

const initialState = {
  events: [],
  eventsLoading: false,
};

const state = { ...initialState };

const actions = {
  async getDeviceEventsAsync({ commit }, { id, page = 1, pageSize = 200 }) {
    try {
      commit("setEventsLoading", true);
      const { data } = await http.get(
        `devices/${id}/events?page=${page}&pageSize=${pageSize}`
      );
      commit("setEvents", data);
    } catch (e) {
      error("Error", `Failed to load device events. ${e.message}`);
      commit("setEvents", []);
    } finally {
      commit("setEventsLoading", false);
    }
  },
  async getSensorEventsAsync({ commit }, { id, page = 1, pageSize = 200 }) {
    try {
      commit("setEventsLoading", true);
      const { data } = await http.get(
        `sensors/${id}/events?page=${page}&pageSize=${pageSize}`
      );
      commit("setEvents", data);
    } catch (e) {
      error("Error", `Failed to load sensor events. ${e.message}`);
      commit("setEvents", []);
    } finally {
      commit("setEventsLoading", false);
    }
  },
  resetState({ commit }) {
    commit("setInitialState");
  },
};

const mutations = {
  setEvents: (state, events) => (state.events = events),
  setEventsLoading: (state, loading) => (state.eventsLoading = loading),
  setInitialState: (state) => Object.assign(state, initialState),
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
