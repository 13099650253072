import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import user from "./modules/user";
import tenant from "./modules/tenant";
import sensors from "./modules/sensor";
import displayGroups from "./modules/display-group";
import alarms from "./modules/alarm";
import devices from "./modules/device";
import events from "./modules/event";

export default new Vuex.Store({
  modules: {
    user,
    tenant,
    sensors,
    displayGroups,
    alarms,
    devices,
    events,
  },
});
