<template>
  <div>
    <v-row>
      <v-col cols="12" lg="4" sm="12">
        <v-form
          ref="informationFormValid"
          v-model="informationFormValid"
          lazy-validation
        >
          <v-card rounded tile>
            <v-card-title primary-title> Sensor Information</v-card-title>
            <v-card-text>
              <v-text-field
                class="mx-2"
                v-model="sensorCopy.name"
                label="Name"
                :rules="rules.name"
                required
                filled
              ></v-text-field>
              <v-textarea
                class="mx-2"
                dense
                :auto-grow="true"
                rows="5"
                label="Description"
                v-model="sensorCopy.description"
                filled
              ></v-textarea>
              <v-text-field
                class="mx-2"
                dense
                filled
                :auto-grow="true"
                label="Value Offset"
                :rules="rules.valueOffset"
                v-model.number="sensorCopy.valueOffset"
              ></v-text-field>
              <v-row dense>
                <v-col cols="6">
                  <v-text-field
                    class="mx-2"
                    dense
                    filled
                    :auto-grow="true"
                    label="Min Display Range"
                    :rules="rules.minDisplayRange"
                    v-model.number="sensorCopy.minDisplayRange"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    class="mx-2"
                    dense
                    filled
                    :auto-grow="true"
                    label="Max Display Range"
                    :rules="rules.maxDisplayRange"
                    v-model.number="sensorCopy.maxDisplayRange"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="success"
                tile
                @click="updateSensor"
                :disabled="sensorLoading || !informationFormValid"
                >Update Sensor</v-btn
              >
            </v-card-actions>
          </v-card></v-form
        >
      </v-col>
      <v-col cols="12" lg="8" sm="12">
        <v-form ref="rulesFormValid" v-model="rulesFormValid" lazy-validation>
          <v-card tile rounded>
            <v-card-title primary-title> Alarm Definitions </v-card-title>
            <v-card-text>
              <v-checkbox
                class="mt-0"
                :label="
                  alarmRulesCopy.enabled ? 'Alarms Enabled' : 'Alarms Disabled'
                "
                hide-details
                :value="alarmRulesCopy.enabled"
                @change="enableAlarmRules"
              ></v-checkbox>
              <v-data-table
                :loading="alarmRulesLoading"
                :loading-text="''"
                :items="alarmRulesCopy.alarmRules"
                :headers="headers"
                disable-filtering
                disable-sort
                hide-default-footer
              >
                <template v-slot:item.enabled="{ item }">
                  <v-checkbox
                    v-model="item.enabled"
                    :disabled="!alarmRulesCopy.enabled || alarmRulesLoading"
                    :loading="alarmRulesLoading"
                    :hide-details="true"
                    class="pt-0 mt-0"
                  ></v-checkbox>
                </template>
                <template v-slot:item.conditions="{ item }">
                  <v-row dense al>
                    <v-col
                      cols="12"
                      v-for="(condition, i) in item.conditions"
                      :key="i"
                    >
                      <v-row dense align="center">
                        <v-col cols="12" md="2" sm="3">Reading value</v-col>
                        <v-col cols="12" md="5" sm="6"
                          ><v-select
                            disabled
                            v-model="condition.operator"
                            :hide-details="true"
                            item-text="text"
                            item-value="value"
                            :items="operators"
                            outlined
                            dense
                          >
                          </v-select
                        ></v-col>
                        <v-col cols="12" md="5" sm="3">
                          <v-text-field
                            v-model.number="condition.threshold"
                            :disabled="
                              !alarmRulesCopy.enabled || alarmRulesLoading
                            "
                            :loading="alarmRulesLoading"
                            :hide-details="true"
                            :rules="rules.conditionValue"
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:item.repeatAlarms="{ item }">
                  <v-checkbox
                    v-model.number="item.repeatAlarms"
                    :disabled="!alarmRulesCopy.enabled || alarmRulesLoading"
                    :loading="alarmRulesLoading"
                    :hide-details="true"
                    class="pt-0 mt-0"
                  ></v-checkbox>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="success"
                @click="updateAlarmRules"
                :disabled="
                  !alarmRulesCopy.enabled ||
                  alarmRulesLoading ||
                  !rulesFormValid
                "
                :loading="alarmRulesLoading"
                >Update Alarms</v-btn
              >
              <div class="ml-2">
                <span class="caption"
                  >Note. It may take up to 30 minutes for alarm rule updates to
                  take effect.</span
                >
              </div>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  props: ["id"],
  data() {
    return {
      sensorCopy: {
        id: "",
        name: "",
        description: "",
        valueOffset: 0,
        minDisplayRange: 0,
        maxDisplayRange: 0,
      },
      alarmRulesCopy: {
        id: "",
        enabled: false,
        alarmRules: [],
      },
      operators: [
        { value: ">", text: "Greater than" },
        { value: ">=", text: "Greater or equals to" },
        { value: "===", text: "Equals" },
        { value: "!==", text: "Not Equals" },
        { value: "<", text: "Less than" },
        { value: "<=", text: "Less than or equals to" },
      ],
      headers: [
        { text: "Enabled", value: "enabled" },
        { text: "Name", value: "name" },
        { text: "Severity", value: "severity" },
        { text: "Condition", value: "conditions" },
        // { text: "Repeat Notifications", value: "repeatAlarms" },
      ],
      rules: {
        name: [(v) => !!v || "Sensor name is required"],
        valueOffset: [(v) => !isNaN(v) || "Value offset must be a number"],
        minDisplayRange: [
          (v) => !isNaN(v) || "Minimum display range must be a number",
        ],
        maxDisplayRange: [
          (v) => !isNaN(v) || "Max display range must be a number",
        ],
        conditionValue: [
          (v) => !isNaN(v) || "Condition rule value must be a number",
        ],
      },
      informationFormValid: true,
      rulesFormValid: true,
    };
  },
  async created() {
    await this.getAlarmRulesAsync(this.id);
    this.alarmRulesCopy = { ...this.alarmRules };
    this.sensorCopy = { ...this.sensor };
  },
  methods: {
    ...mapActions("alarms", [
      "getAlarmRulesAsync",
      "updateAlarmRulesAsync",
      "enableAlarmRuleGroupAsync",
    ]),
    ...mapActions("sensors", ["updateSensorAsync"]),
    async updateAlarmRules() {
      this.$refs.rulesFormValid.resetValidation();
      if (this.rulesFormValid) {
        await this.updateAlarmRulesAsync({
          id: this.id,
          alarmRules: this.alarmRulesCopy.alarmRules,
        });
      }
    },
    async updateSensor() {
      this.$refs.informationFormValid.resetValidation();
      if (this.informationFormValid) {
        await this.updateSensorAsync(this.sensorCopy);
      }
    },
    async enableAlarmRules(enabled) {
      await this.enableAlarmRuleGroupAsync({ id: this.id, enable: !!enabled });
      this.alarmRulesCopy.enabled = this.alarmRules.enabled;
    },
  },
  computed: {
    ...mapState("sensors", ["sensor", "sensorLoading"]),
    ...mapState("alarms", ["alarmRules", "alarmRulesLoading"]),
  },
};
</script>
