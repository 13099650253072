<template>
  <v-row>
    <v-col cols="12"> <Events :deviceId="id" /></v-col>
  </v-row>
</template>

<script>
import Events from "../../../components/device-sensor-events.vue";
import store from "@/store";

export default {
  props: ["id"],
  beforeRouteLeave(to, from, next) {
    store.dispatch("events/resetState", null, { root: true });
    next();
  },
  components: {
    Events,
  },
};
</script>
