import axios from "axios";
import Vue from "vue";

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL + "/api/",
});

httpClient.interceptors.request.use(
  async (options) => {
    const accessToken = await Vue.prototype.$msal.acquireToken();

    if (!accessToken) {
      throw new Error("HttpInterceptor. Access token is undefined.");
    }

    options.headers.Authorization = "Bearer " + accessToken;

    const tenant = localStorage.getItem("tenantFilter");

    if (tenant) {
      options.headers["display-tenant"] = tenant;
    }

    return options;
  },
  (error) => {
    return Promise.reject(error);
  }
);

httpClient.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    // const response = error.response;
    // console.log("Axios Interceptor 001", error.response);
    return Promise.reject(error);
  }
);

export default httpClient;
