<template>
  <v-app v-if="iframe === false">
    <v-app-bar app flat dense v-if="$route.name !== 'auth'">
      <v-toolbar-title @click="$router.push('/display-groups')"
        >IOWATCH</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-btn text to="/display-groups">Display Groups</v-btn>
      <v-btn text to="/sensors">Sensors</v-btn>
      <v-btn text to="/devices">Devices</v-btn>
      <v-btn text to="/alarms">Alarms</v-btn>
      <v-btn text to="/user">Account</v-btn>
      <v-btn @click="logout" text>
        <v-icon>mdi-logout-variant</v-icon>
        <span>Sign Out</span>
      </v-btn>
    </v-app-bar>

    <!-- <v-app-bar clipped-left dense flat v-if="$route.name !== 'auth'">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="hidden-md-and-up"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>IOWATCH</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn text to="/display-groups">Display Groups</v-btn>
        <v-btn text to="/sensors">Sensors</v-btn>
        <v-btn text to="/devices">Devices</v-btn>
        <v-btn text to="/alarms">Alarms</v-btn>
        <v-btn text to="/user">Account</v-btn>
        <v-btn @click="logout" text>
          <v-icon>mdi-logout-variant</v-icon>
          <span>Sign Out</span>
        </v-btn>
      </v-toolbar-items>
    </v-app-bar> -->
    <!-- 
    <v-navigation-drawer
      v-model="drawer"
      temporary
      absolute
      width="200"
      id="drawer"
      v-if="$route.name !== 'auth'"
    >
      <v-list>
        <v-list-item to="/display-groups">
          <v-list-item-content>
            <v-list-item-title>Display Groups</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/sensors">
          <v-list-item-content>
            <v-list-item-title>Sensors</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/devices">
          <v-list-item-content>
            <v-list-item-title>Devices</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/alarms">
          <v-list-item-content>
            <v-list-item-title>Alarms</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/user">
          <v-list-item-content>
            <v-list-item-title>Account</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-content>
            <v-list-item-title>Sign Out</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer> -->

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
    <Notification />
  </v-app>
</template>

<script>
import Notification from "./components/notification.vue";

export default {
  data() {
    return {
      drawer: null,
    };
  },
  created() {
    this.$vuetify.theme.dark = true;
  },
  methods: {
    async logout() {
      localStorage.removeItem("user");
      localStorage.removeItem("tenant");
      localStorage.removeItem("tenantFilter");
      await this.$msal.logout();
    },
  },
  computed: {
    iframe() {
      const iframe = window !== window.parent && !window.opener;
      return iframe;
    },
    userName() {
      const account = this.$msal.getAccount();
      if (account) {
        return account.userName.split("@")[0];
      } else {
        return "";
      }
    },
  },
  components: {
    Notification,
  },
};
</script>
