import { error, success } from "../../helpers/notifications";
import http from "../../services/http";

const initialState = {
  displayGroups: [],
  displayGroupsLoading: false,
  displayGroup: undefined,
  displayGroupLoading: false,
};

const state = { ...initialState };

const actions = {
  async getDisplayGroupsAsync({ commit }) {
    try {
      commit("setDisplayGroupsLoading", true);
      const { data } = await http.get("sensorgroups");
      commit("setDisplayGroups", data);
    } catch (e) {
      commit("setDisplayGroups", []);
      error("Error", "Failed to load display groups. " + e.message);
    } finally {
      commit("setDisplayGroupsLoading", false);
    }
  },
  async getDisplayGroupAsync({ commit }, id) {
    try {
      commit("setDisplayGroupLoading", true);
      const { data } = await http.get("sensorgroups/" + id);
      commit("setDisplayGroup", data);
    } catch (e) {
      error("Error", "Failed to load display group. " + e.message);
    } finally {
      commit("setDisplayGroupLoading", false);
    }
  },
  async updateDisplayGroupAsync({ commit }, { id, displayGroup }) {
    try {
      commit("setDisplayGroupLoading", true);
      await http.put(`sensorgroups/${id}`, displayGroup);
      commit("setDisplayGroup", displayGroup);
      success("Success", `${displayGroup.name} display group updated.`);
    } catch (e) {
      error(
        "Error",
        `Failed to update ${displayGroup.name} display group. ${e.message}`
      );
    } finally {
      commit("setDisplayGroupLoading", false);
    }
  },
  async deleteDisplayGroupAsync({ commit }, id) {
    console.log(id);
    try {
      commit("setDisplayGroupLoading", true);
      await http.delete(`sensorgroups/${id}`);
      success("Success", `Display group deleted.`);
    } catch (e) {
      error("Error", `Failed to delete display group. ${e.message}`);
    } finally {
      commit("setDisplayGroupLoading", false);
    }
  },
  async createDisplayGroupAsync({ commit }, displayGroup) {
    try {
      commit("setDisplayGroupLoading", true);
      await http.post("sensorgroups", displayGroup);
      success("Success", `${displayGroup.name} display group created.`);
    } catch (e) {
      error(
        "Error",
        `Failed to create ${displayGroup.name} display group. ${e.message}`
      );
    } finally {
      commit("setDisplayGroupLoading", false);
    }
  },
  resetState({ commit }) {
    commit("setInitialState");
  },
};

const mutations = {
  setDisplayGroups: (state, groups) => (state.displayGroups = groups),
  setDisplayGroupsLoading: (state, loading) =>
    (state.displayGroupsLoading = loading),
  setDisplayGroup: (state, group) => (state.displayGroup = group),
  setDisplayGroupLoading: (state, loading) =>
    (state.displayGroupLoading = loading),
  setInitialState: (state) => Object.assign(state, initialState),
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
