<template>
  <div>
    <notifications
      group="success"
      width="75%"
      position="bottom center"
      classes="ma-4 green"
    />
    <notifications
      group="info"
      width="75%"
      position="bottom center"
      classes="ma-4 blue"
    />
    <notifications
      group="warn"
      width="75%"
      position="bottom center"
      classes="ma-4 yellow"
    />
    <notifications
      group="error"
      width="75%"
      position="bottom center"
      classes="ma-4 red"
    />
  </div>
</template>

<script>
export default {};
</script>
