// import { error } from "../../helpers/notifications";
import http from "../../services/http";

const intitialState = {
  tenant: undefined,
  tenantLoading: false,
  tenantFilter: undefined,
};

const state = { ...intitialState };

const actions = {
  async getTenantAsync({ commit }) {
    commit("setTenantLoading", true);
    let tenant = localStorage.getItem("tenant");

    const { data } = await http.get("tenant");
    tenant = data;
    localStorage.setItem("tenant", JSON.stringify(tenant));

    let tenantFilter = localStorage.getItem("tenantFilter");

    if (!tenantFilter || tenantFilter === null) {
      tenantFilter = tenant.id;
      localStorage.setItem("tenantFilter", tenantFilter);
    } else {
      if (!tenant.tenantPermissions.find((x) => x.id === tenantFilter)) {
        tenantFilter = tenant.id;
        localStorage.setItem("tenantFilter", tenantFilter);
      }
    }

    commit("setTenant", tenant);
    commit("setTenantFilter", tenantFilter);
    commit("setTenantLoading", false);
  },
  setTenantFilter({ commit }, tenantFilter) {
    commit("setTenantFilter", tenantFilter);
    localStorage.setItem("tenantFilter", tenantFilter);
  },
};

const mutations = {
  setTenant: (state, tenant) => (state.tenant = tenant),
  setTenantLoading: (state, loading) => (state.tenantLoading = loading),
  setTenantFilter: (state, tenantFilter) => (state.tenantFilter = tenantFilter),
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
