<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pb-0 pt-1">
            <span class="ml-2">{{ device.name }}</span>
            <span class="caption pt-1">
              <div v-if="device.online" class="d-inline-block">
                <v-icon color="green"> mdi-broadcast </v-icon>
                Online
              </div>
              <div v-if="!device.online" class="d-inline-block">
                <v-icon color="red"> mdi-broadcast </v-icon>
                Offline
              </div>
              <div class="d-inline-block">
                <span class="mx-2">|</span>Status last updated
                {{ device.statusLastUpdatedUtc | formatDate }}
              </div></span
            >
          </v-card-title>
          <v-card-text>
            <v-tabs :disabled="deviceLoading" v-model="tab">
              <v-tab v-for="tab in tabs" :key="tab.id" :to="tab.route" exact>
                {{ tab.name }}</v-tab
              >
            </v-tabs>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div v-if="!deviceLoading || !initialLoad">
          <router-view :id="id"></router-view>
        </div>
        <div v-else>
          <v-card>
            <v-card-title> Loading Device...</v-card-title>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import store from "@/store";

export default {
  props: ["id"],
  beforeRouteLeave(to, from, next) {
    store.dispatch("devices/resetState", null, { root: true });
    next();
  },
  data() {
    return {
      initialLoad: true,
      tab: `/devices/${this.id}`,
      tabs: [
        {
          id: 1,
          name: "Configuration",
          route: `/devices/${this.id}`,
        },
        {
          id: 2,
          name: "Events",
          route: `/devices/${this.id}/events`,
        },
      ],
    };
  },
  async created() {
    await this.getDeviceAsync(this.id);
    this.initialLoad = false;
  },
  methods: {
    ...mapActions("devices", ["getDeviceAsync"]),
  },
  computed: {
    ...mapState("devices", ["device", "deviceLoading"]),
  },
};
</script>
