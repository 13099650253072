<template>
  <v-data-table
    disable-sort
    :items="events"
    :headers="headers"
    :items-per-page="200"
    :loading="eventsLoading"
    :loading-text="'Loading events...'"
    no-data-text="No events"
    no-results-text=""
    hide-default-footer
    :height="'65vh'"
  >
    <template v-slot:top>
      <v-row dense align="end" class="ma-2">
        <v-col cols="12" sm="4" lg="1">
          <v-btn color="success" tile block @click="getEvents"
            ><v-icon>mdi-refresh</v-icon>Refresh</v-btn
          >
        </v-col>
      </v-row>
    </template>
    <template v-slot:item.eventTimeUtc="{ item }">
      {{ item.eventTimeUtc | formatDate }}
    </template>
    <template v-slot:footer>
      <v-row dense>
        <v-col class="my-2 ml-4" cols="12">
          <v-btn
            outlined
            tile
            color="white"
            :disabled="page < 2"
            @click="(page = page - 1), getEvents()"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <span class="mx-4 caption">Page {{ page }} </span>
          <v-btn
            :disabled="events.length === 0 || eventsLoading"
            outlined
            tile
            color="white"
            @click="(page = page + 1), getEvents()"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  props: ["deviceId", "sensorId"],
  data() {
    return {
      page: 1,
      search: "",
    };
  },
  async created() {
    await this.getEvents();
  },
  methods: {
    ...mapActions("events", ["getSensorEventsAsync", "getDeviceEventsAsync"]),
    async getEvents() {
      if (this.deviceId && !this.sensorId) {
        await this.getDeviceEventsAsync({ id: this.deviceId, page: this.page });
      } else if (!this.deviceId && this.sensorId) {
        await this.getSensorEventsAsync({ id: this.sensorId, page: this.page });
      }
    },
  },
  computed: {
    ...mapState("events", ["events", "eventsLoading"]),
    headers() {
      if (this.deviceId && !this.sensorId) {
        return [
          { text: "Event", value: "eventName" },
          { text: "Time", value: "eventTimeUtc" },
        ];
      } else {
        return [
          { text: "Event", value: "eventName" },
          { text: "Device", value: "deviceId" },
          { text: "Time", value: "eventTimeUtc" },
        ];
      }
    },
  },
};
</script>
