<template>
  <div>
    <v-icon class="mr-1" :color="severityColor">mdi-alert</v-icon
    ><span v-show="caption" class="severity">{{ severity }}</span>
  </div>
</template>

<script>
export default {
  props: {
    severity: {
      require: true,
      type: String,
    },
    caption: {
      require: false,
      type: Boolean,
      default: true,
    },
  },
  computed: {
    severityColor() {
      if (this.severity === "normal") {
        return "green";
      } else if (this.severity === "informational") {
        return "blue";
      } else if (this.severity === "warning") {
        return "orange";
      } else if (this.severity === "critical") {
        return "red";
      } else return "primary";
    },
  },
};
</script>
<style scoped>
.severity {
  text-transform: capitalize;
}
</style>
