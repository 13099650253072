<template>
  <div>
    <v-form ref="form" v-model="formValid" lazy-validation>
      <v-row>
        <v-col cols="12" lg="4">
          <v-card :loading="tenantLoading || userLoading">
            <v-card-title primary-title>
              {{ user.firstName }} {{ user.lastName }}
            </v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" sm="12">
                  <v-select
                    v-if="tenantPermissions.length !== 0"
                    outlined
                    :items="tenantPermissions"
                    :item-text="'name'"
                    :item-value="'id'"
                    label="Display Tenant"
                    @change="applyTenantFilter"
                    :value="tenantFilter"
                    :loading="tenantLoading"
                    hide-details
                  >
                  </v-select>
                  <v-text-field
                    class="mt-2"
                    v-model="userCopy.firstName"
                    :rules="rules.firstName"
                    label="First Name"
                    required
                    filled
                  ></v-text-field>
                  <v-text-field
                    v-model="userCopy.lastName"
                    :rules="rules.lastName"
                    label="Last Name"
                    required
                    filled
                  ></v-text-field>
                  <v-text-field
                    class="mt-2"
                    v-model="userCopy.contactInformation.email"
                    :rules="rules.emailRule"
                    label="Email"
                    required
                    filled
                  ></v-text-field>
                  <v-text-field
                    class="mt-2"
                    v-model="userCopy.contactInformation.mobilePhone"
                    label="Mobile Phone (with country code +AAABBBCCCDDD)"
                    :rules="rules.phone"
                    required
                    filled
                  ></v-text-field>
                  <v-checkbox
                    v-model="
                      userCopy.notificationPreferences.receiveSMSNotifications
                    "
                    label="Receive SMS notifications"
                    :hide-details="true"
                    class="mt-2"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="
                      userCopy.notificationPreferences.receiveEmailNotifications
                    "
                    label="Receive Email notifications"
                    :hide-details="true"
                    class="mt-2"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                tile
                color="success"
                @click="updateInformation"
                :disabled="!formValid"
                >Update Information</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { success } from "../../helpers/notifications";
import { mapActions, mapState } from "vuex";
export default {
  async created() {
    await this.getTenantAsync();
    await this.getUserAsync();
    this.userCopy = { ...this.user };
  },
  data() {
    return {
      userCopy: {
        id: undefined,
        firstName: "",
        lastName: "",
        contactInformation: {
          email: "",
          mobilePhone: "",
        },
        notificationPreferences: {
          receiveSMSNotifications: undefined,
          receiveEmailNotifications: undefined,
        },
      },
      rules: {
        firstName: [(v) => !!v || "Name is required"],
        lastName: [(v) => !!v || "Last name is required"],
        email: [
          (v) => !!v || "Email is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        phone: [
          (v) => !!v || "Phone is required",
          (v) =>
            /^\+[1-9]{1}[0-9]{3,14}$/.test(v) ||
            "Phone must be valid (+000111222333)",
        ],
      },
      formValid: true,
    };
  },
  methods: {
    ...mapActions("tenant", ["getTenantAsync", "setTenantFilter"]),
    ...mapActions("user", ["getUserAsync", "updateUserAsync"]),
    applyTenantFilter(id) {
      this.setTenantFilter(id);
      const newTenant = this.tenant.tenantPermissions.find((x) => x.id === id);
      success("Tenant changed", `Tenant changed to ${newTenant.name}.`);
    },
    async updateInformation() {
      this.$refs.form.resetValidation();

      if (this.formValid) {
        //console.log(this.userCopy.notificationPreferences);
        await this.updateUserAsync(this.userCopy);
      }
    },
  },
  computed: {
    ...mapState("tenant", ["tenant", "tenantLoading", "tenantFilter"]),
    ...mapState("user", ["user", "userLoading"]),
    tenantPermissions() {
      if (!this.tenant || this.tenant.tenantPermissions.length === 0) return [];
      let tenantPermissions = [...this.tenant.tenantPermissions];
      tenantPermissions.unshift({ id: this.tenant.id, name: this.tenant.name });
      return tenantPermissions;
    },
  },
};
</script>
