<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pb-0 pt-1">
            <!-- <v-btn tile color="success">
              <v-icon>mdi-refresh</v-icon>
              <span>Refresh</span>
            </v-btn> -->
            <span class="ml-2">{{ sensor.name }}</span>
            <!-- <span class="ml-2 caption mt-1" v-if="sensor.lastValue">
              | Last Value: {{ sensor.lastValue | round }} -
              {{ sensor.lastValueTimestampUtc | formatDate }}
            </span> -->
          </v-card-title>
          <v-card-text>
            <v-tabs :disabled="sensorLoading" v-model="tab">
              <v-tab v-for="tab in tabs" :key="tab.id" :to="tab.route" exact>
                {{ tab.name }}</v-tab
              >
            </v-tabs>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div v-if="!sensorLoading || !initialLoad">
          <router-view :id="id"></router-view>
        </div>
        <div v-else>
          <v-card>
            <v-card-title> Loading sensor...</v-card-title>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import store from "@/store";

export default {
  props: ["id"],
  beforeRouteLeave(to, from, next) {
    store.dispatch("sensors/resetState", null, { root: true });
    next();
  },
  data() {
    return {
      initialLoad: true,
      tab: `/sensors/${this.id}`,
      tabs: [
        {
          id: 1,
          name: "Readings",
          route: `/sensors/${this.id}`,
        },
        {
          id: 2,
          name: "Alarms",
          route: `/sensors/${this.id}/alarms`,
        },
        {
          id: 3,
          name: "Events",
          route: `/sensors/${this.id}/events`,
        },
        {
          id: 4,
          name: "Configuration",
          route: `/sensors/${this.id}/configuration`,
        },
      ],
    };
  },
  async created() {
    await this.getSensorAsync(this.id);
    this.initialLoad = false;
  },
  methods: {
    ...mapActions("sensors", ["getSensorAsync"]),
  },
  computed: {
    ...mapState("sensors", ["sensor", "sensorLoading"]),
  },
};
</script>
