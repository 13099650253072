<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-data-table
          disable-sort
          :items="alarmsFormatted"
          :headers="headers"
          hide-default-footer
          :loading="alarmsLoading"
          @click:row="navigateToAlarm"
          :loading-text="''"
          :no-data-text="'No alarms'"
          :items-per-page="200"
          :height="'80vh'"
        >
          <template v-slot:top>
            <v-row dense class="pa-2" align="center">
              <v-col cols="12" lg="1" md="2" sm="4">
                <v-btn
                  @click="getAlarms"
                  :disabled="alarmsLoading"
                  block
                  color="success"
                  tile
                  >Refresh</v-btn
                >
              </v-col>
              <v-col cols="12" lg="1" md="3" sm="4">
                <v-select
                  :disabled="alarmsLoading"
                  :hide-details="true"
                  :items="activeFilters"
                  v-model="activeFilterSelected"
                  item-text="text"
                  item-value="value"
                  label="Alarm state"
                ></v-select>
              </v-col>
              <v-col cols="12" lg="1" md="3" sm="4">
                <v-select
                  :disabled="alarmsLoading"
                  :hide-details="true"
                  :items="acknowledgedFilters"
                  v-model="acknowledgedFilterSelected"
                  item-text="text"
                  item-value="value"
                  label="Acknowledged"
                ></v-select>
              </v-col>
              <v-col cols="12" offset-lg="7" sm="4" md="4" lg="2">
                <v-btn
                  @click="acknowledgeAll"
                  :disabled="alarmsLoading"
                  color="success"
                  tile
                  class="float-right"
                  block
                  >Acknowledge All</v-btn
                >
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.acknowledged="{ item }">
            {{ item.acknowledged ? "Acknowledged" : "Unacknowledged" }}
          </template>
          <template v-slot:item.severity="{ item }">
            <Severity :severity="item.severity" />
          </template>
          <template v-slot:item.sensorName="{ item }">
            {{ item.sensorName }}
          </template>
          <template v-slot:item.alarmStartUtc="{ item }">
            {{ item.alarmStartUtc | formatDate }}
            <span v-if="item.alarmEndUtc"
              >- {{ item.alarmEndUtc | formatDate }}</span
            >
            <span v-else>- Active</span>
          </template>
          <template v-slot:footer>
            <v-row dense>
              <v-col class="my-2 ml-4" cols="12">
                <v-btn
                  outlined
                  tile
                  color="white"
                  :disabled="page < 2"
                  @click="(page = page - 1), getAlarms()"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <span class="mx-4 caption">Page {{ page }} </span>
                <v-btn
                  :disabled="alarmsFormatted.length === 0"
                  outlined
                  tile
                  color="white"
                  @click="(page = page + 1), getAlarms()"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Severity from "../../components/severity.vue";
import store from "@/store";

export default {
  beforeRouteLeave(to, from, next) {
    store.dispatch("alarms/resetState", null, { root: true });
    next();
  },
  data() {
    return {
      page: 1,
      headers: [
        { text: "Acknowledged", width: 150, value: "acknowledged" },
        { text: "Severity", width: 150, value: "severity" },
        { text: "Alarm Name", value: "alarmName" },
        { text: "Sensor", value: "sensorName" },
        { text: "Time", value: "alarmStartUtc" },
      ],
      activeFilterSelected: "",
      activeFilters: [
        { text: "All", value: "" },
        { text: "Active", value: "true" },
        { text: "Inactive", value: "false" },
      ],
      acknowledgedFilters: [
        { text: "All", value: "" },
        { text: "Acknowledged", value: "true" },
        { text: "Unacknowledged", value: "false" },
      ],
      acknowledgedFilterSelected: "",
      sensorFilter: "",
    };
  },
  async created() {
    await this.getAlarms();
  },
  methods: {
    ...mapActions("alarms", ["getAlarmsAsync", "ackAllAlarmsAsync"]),
    async getAlarms() {
      await this.getAlarmsAsync({
        acknowledged: this.acknowledgedFilterSelected,
        active: this.activeFilterSelected,
        page: this.page,
      });
    },
    navigateToAlarm({ id }) {
      this.$router.push({
        path: `alarms/${id}`,
      });
    },
    async acknowledgeAll() {
      if (confirm("Acknowledge all system alarms?")) {
        await this.ackAllAlarmsAsync("");
        await this.getAlarms();
      }
    },
  },
  watch: {
    sensorId: async function (newId, oldId) {
      if (newId !== oldId) {
        await this.getAlarmsAsync({ id: newId });
      }
    },
  },
  computed: {
    ...mapState("alarms", ["alarmsLoading"]),
    ...mapGetters("alarms", ["alarmsFormatted"]),
    sensorId() {
      return this.$route.query.sensor;
    },
  },
  components: {
    Severity,
  },
};
</script>
