<template>
  <prism-editor
    class="json-editor"
    v-model="jsonCopy"
    :highlight="highlighter"
    :line-numbers="true"
    @input="emitJson"
  ></prism-editor>
</template>

<script>
import * as stringify from "json-stringify-pretty-compact";
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";

import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css";

export default {
  props: {
    json: {
      type: Object,
      require: false,
      default: () => {},
    },
  },
  data() {
    return {
      jsonCopy: stringify({ ...this.json }),
    };
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.js);
    },
    emitJson() {
      try {
        const json = JSON.parse(this.jsonCopy);
        this.$emit("valid", true);
        this.$emit("change", json);
      } catch (_) {
        this.$emit("valid", false);
      }
    },
  },
  components: {
    PrismEditor,
  },
};
</script>

<style scoped>
.json-editor {
  height: 100%;
  background-color: black;
  font-size: 16px;
}
.prism-editor__textarea {
  color: white;
  caret-color: white;
}
</style>
