import "./filters";
import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";

import MSAL from "./services/msal";
const msal = new MSAL();
Vue.use(msal);
Vue.prototype.$msal = msal;

// import MSAL from "./services/msal-browser";
// const msal = new MSAL();
// Vue.use(msal);
// Vue.prototype.$msal = msal;

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

import Notifications from "vue-notification";
Vue.use(Notifications);

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
