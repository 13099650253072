import { error, success } from "../../helpers/notifications";
import http from "../../services/http";

const initialState = {
  sensors: [],
  sensorsLoading: false,
  sensor: {
    name: "",
    lastValueTimestampUtc: new Date(),
  },
  sensorLoading: false,
  readings: [],
  readingsLoading: false,
};

const state = { ...initialState };

const actions = {
  async getSensorsAsync({ commit }) {
    try {
      commit("setSensorsLoading", true);
      const { data } = await http.get("sensors");
      commit("setSensors", data);
    } catch (e) {
      commit("setSensors", []);
      error("Error", `Failed to load sensors. ${e.message}`);
    } finally {
      commit("setSensorsLoading", false);
    }
  },
  async getSensorAsync({ commit }, id) {
    try {
      commit("setSensorLoading", true);
      const { data } = await http.get("sensors/" + id);
      commit("setSensor", data);
    } catch (e) {
      error("Error", `Failed to load sensor. ${e.message}`);
    } finally {
      commit("setSensorLoading", false);
    }
  },
  async updateSensorAsync({ commit }, sensor) {
    try {
      commit("setSensorLoading", true);
      await http.put(`sensors/${sensor.id}`, sensor);
      commit("setSensor", sensor);
      success("Success", `${sensor.name} updated.`);
    } catch (e) {
      error("Error", `Failed to update ${sensor.name} sensor. ${e.message}`);
    } finally {
      commit("setSensorLoading", false);
    }
  },
  async getReadingsAsync({ commit }, { id, from, to, granuality }) {
    try {
      commit("setReadingsLoading", true);
      const { data } = await http.get(
        `sensors/${id}/readings?from=${from}&to=${to}&granuality=${granuality}`
      );
      commit("setReadings", data);
    } catch (e) {
      commit("setReadings", []);
      error("Error", `Failed to load sensor readings. ${e.message}`);
    } finally {
      commit("setReadingsLoading", false);
    }
  },
  resetState({ commit }) {
    commit("setInitialState");
  },
};

const mutations = {
  setSensors: (state, sensors) => (state.sensors = sensors),
  setSensorsLoading: (state, loading) => (state.sensorsLoading = loading),
  setSensor: (state, sensor) => (state.sensor = sensor),
  setSensorLoading: (state, loading) => (state.sensorLoading = loading),
  setReadings: (state, readings) => (state.readings = readings),
  setReadingsLoading: (state, loading) => (state.readingsLoading = loading),
  setInitialState: (state) => Object.assign(state, initialState),
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
