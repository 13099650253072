<template>
  <apexchart
    width="100%"
    height="100%"
    :series="values"
    :options="chartOptions"
  ></apexchart>
</template>
<script>
import moment from "moment";

export default {
  props: {
    values: {
      require: false,
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          animations: {
            enabled: false,
          },
          background: "transparent",
          foreColor: "#ffffff",
          type: "line",
        },
        stroke: {
          curve: "straight",
          colors: ["#00FF00", "#0000FF", "#FF0000"],
          width: [7, 3, 3],
        },
        fill: {
          colors: undefined,
          // type: "solid",
          // opacity: 0.7,
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          show: true,
          borderColor: "#fff",
          strokeDashArray: 1,
          position: "back",
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        markers: {
          size: 0,
          // hover: {
          //   sizeOffset: 5,
          // },
          // colors: ["#2196f3"],
        },
        noData: {
          align: "center",
          verticalAlign: "middle",
          text: "No readings",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#ffffff",
            fontSize: "36px",
          },
        },
        theme: {
          mode: "dark",
        },
        tooltip: {
          enabled: true,
          theme: "dark",
          marker: {
            show: false,
          },
          x: {
            show: true,
            formatter: function (value) {
              return moment.unix(value / 1000).format("MM-DD hh:mm:ss A");
            },
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            // eslint-disable-next-line
            formatter: function (value, timestamp, opts) {
              return moment.unix(timestamp / 1000).format("MM-DD hh:mm:ss A");
            },
            rotate: 0,
            rotateAlways: false,
          },
          tickAmount: 12,
        },
        yaxis: {
          tickAmount: 10,
          labels: {
            formatter: (value) => parseFloat(value).toFixed(3),
          },
          min: function (min) {
            return min - 5;
          },
          max: function (max) {
            return max + 5;
          },
        },
        legend: {
          position: "top",
          fontSize: "14px",
          markers: {
            fillColors: ["#00FF00", "#0000FF", "#FF0000"],
          },
        },
      },
    };
  },
};
</script>
