<template>
  <div>
    <v-form ref="form" v-model="formValid">
      <v-sheet>
        <v-row dense align="center" class="mx-2 mb-2">
          <v-col cols="12" sm="12" md="2">
            <v-text-field
              :loading="displayGroupLoading"
              :disabled="displayGroupLoading"
              v-model="displayGroupCopy.name"
              :rules="rules.displayGroupName"
              label="Group name"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-text-field
              :loading="displayGroupLoading"
              :disabled="displayGroupLoading"
              v-model="displayGroupCopy.description"
              label="Group description"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="1">
            <v-checkbox
              :loading="displayGroupLoading"
              :disabled="displayGroupLoading"
              v-model="displayGroupCopy.defaultGroup"
              label="Default Group"
              hide-details
            >
            </v-checkbox>
            <span class="caption">System default.</span>
          </v-col>
          <v-col cols="12" sm="12" md="3" v-if="hasId">
            <v-row dense>
              <v-col cols="12" md="6">
                <v-btn color="primary" block tile @click="saveDisplayGroup"
                  >Save Changes</v-btn
                >
              </v-col>
              <v-col cols="12" md="6">
                <v-btn color="error" block tile @click="deleteDisplayGroup"
                  >Delete Group</v-btn
                >
              </v-col>
            </v-row>

            <!-- Editing existing group -->
          </v-col>
          <v-col cols="12" sm="12" md="3" v-if="!hasId">
            <!-- Creating a new group -->
            <v-btn color="success" block tile @click="createDisplayGroup"
              >Create Group</v-btn
            >
          </v-col>
        </v-row></v-sheet
      >
      <v-sheet>
        <v-row dense>
          <v-col cols="12" lg="6" sm="12">
            <v-btn
              color="red"
              class="ml-2"
              tile
              small
              @click="removeSelected"
              :loading="displayGroupLoading"
              :disabled="displayGroupLoading"
              ><v-icon>mdi-minus-circle-outline</v-icon>Remove Selected</v-btn
            >
            <v-data-table
              class="mx-2"
              :items-per-page="1000"
              :height="'65vh'"
              no-data-text="No sensors in group"
              no-results-text=""
              loading-text="Loading sensors..."
              :loading="displayGroupLoading"
              v-model="selectedToRemove"
              show-select
              disable-sort
              :items="displayGroupCopy.groupSensors"
              :headers="inGroupHeaders"
              :search="searchInGroup"
            >
              <template v-slot:top>
                <v-text-field
                  class="mb-2"
                  v-model="searchInGroup"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  color="white"
                  icon
                  outlined
                  tile
                  x-small
                  @click="orderUp(item)"
                >
                  <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
                <v-btn
                  color="white"
                  icon
                  outlined
                  tile
                  x-small
                  @click="orderDown(item)"
                >
                  <v-icon>mdi-arrow-down</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" lg="6" sm="12">
            <v-btn
              color="green"
              class="ml-2"
              tile
              small
              @click="addSelected"
              :loading="displayGroupLoading"
              :disabled="displayGroupLoading"
              ><v-icon>mdi-plus-circle-outline</v-icon>Add Selected</v-btn
            >
            <v-data-table
              class="mx-2"
              :items-per-page="1000"
              :height="'65vh'"
              no-data-text=""
              no-results-text=""
              loading-text="Loading sensors..."
              :loading="sensorsLoading"
              v-model="selectedToAdd"
              show-select
              disable-sort
              :items="sensorsNotInGroup"
              :headers="headers"
              :search="searchInSensors"
            >
              <template v-slot:top>
                <v-text-field
                  class="mb-2"
                  v-model="searchInSensors"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-sheet>
    </v-form>
  </div>
</template>

<script>
import { error } from "../../helpers/notifications";
import { mapActions, mapState } from "vuex";
export default {
  props: {
    id: {
      require: false,
      type: String,
    },
  },
  data() {
    return {
      inGroupHeaders: [
        { text: "Order", width: 100, value: "actions" },
        { text: "Name", value: "name" },
        { text: "Type", value: "sensorType" },
      ],
      headers: [
        { text: "Name", value: "name" },
        { text: "Type", value: "sensorType" },
      ],
      selectedToAdd: [],
      selectedToRemove: [],
      displayGroupCopy: {
        name: "",
        description: "",
        defaultGroup: false,
        groupSensors: [],
      },
      searchInGroup: "",
      searchInSensors: "",
      rules: {
        displayGroupName: [
          (v) => !!v || "Display group name is required",
          (v) =>
            (v && v.length >= 4) ||
            "Display group name must be at least 4 characters",
        ],
      },
      formValid: true,
    };
  },
  async created() {
    // Ugly fix to check if provided route id is UUID or not
    if (this.hasId) {
      await this.getDisplayGroupAsync(this.id);
      this.displayGroupCopy = { ...this.displayGroup };
    }
    await this.getSensorsAsync();
  },
  methods: {
    ...mapActions("sensors", ["getSensorsAsync"]),
    ...mapActions("displayGroups", [
      "getDisplayGroupAsync",
      "updateDisplayGroupAsync",
      "deleteDisplayGroupAsync",
      "createDisplayGroupAsync",
    ]),
    addSelected() {
      this.selectedToAdd.forEach((s) => {
        this.displayGroupCopy.groupSensors.push(s);
      });
      this.selectedToAdd = [];
    },
    removeSelected() {
      this.selectedToRemove.forEach((s) => {
        const index = this.displayGroupCopy.groupSensors.findIndex(
          (x) => x.id === s.id
        );
        this.displayGroupCopy.groupSensors.splice(index, 1);
      });
      this.selectedToRemove = [];
    },
    orderUp(item) {
      const index = this.displayGroupCopy.groupSensors.findIndex(
        (x) => x.id === item.id
      );
      this.move(this.displayGroupCopy.groupSensors, index, -1);
    },
    orderDown(item) {
      const index = this.displayGroupCopy.groupSensors.findIndex(
        (x) => x.id === item.id
      );
      this.move(this.displayGroupCopy.groupSensors, index, 1);
    },
    move(array, index, delta) {
      const newIndex = index + delta;
      if (newIndex < 0 || newIndex == array.length) return;
      const indexes = [index, newIndex].sort((a, b) => a - b);
      array.splice(indexes[0], 2, array[indexes[1]], array[indexes[0]]);
    },
    async saveDisplayGroup() {
      this.$refs.form.validate();

      if (this.displayGroupCopy.groupSensors.length === 0) {
        error("Error", "Display group must contain at least 1 sensor");
        return;
      } else if (this.formValid) {
        const updateModel = { ...this.displayGroupCopy };
        updateModel.groupSensors = updateModel.groupSensors.map((s) => s.id);
        await this.updateDisplayGroupAsync({
          id: this.id,
          displayGroup: updateModel,
        });
      }
    },
    async deleteDisplayGroup() {
      if (confirm(`Delete ${this.displayGroupCopy.name} display group?`)) {
        await this.deleteDisplayGroupAsync(this.id);
        this.$router.push({
          path: "/display-groups",
        });
      }
    },
    async createDisplayGroup() {
      this.$refs.form.validate();

      if (this.displayGroupCopy.groupSensors.length === 0) {
        error("Error", "Display group must contain at least 1 sensor");
        return;
      } else if (this.formValid) {
        const createModel = { ...this.displayGroupCopy };
        createModel.groupSensors = createModel.groupSensors.map((s) => s.id);
        await this.createDisplayGroupAsync(createModel);
        this.$router.push({
          path: "/display-groups",
        });
      }
    },
  },
  computed: {
    ...mapState("sensors", ["sensors", "sensorsLoading"]),
    ...mapState("displayGroups", ["displayGroup", "displayGroupLoading"]),
    sensorsNotInGroup() {
      return this.sensors.filter(
        (s) =>
          this.displayGroupCopy.groupSensors.findIndex((x) => x.id === s.id) ===
          -1
      );
    },
    hasId() {
      return /^([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})|[0-9]+$/i.test(
        this.id
      );
    },
  },
};
</script>
