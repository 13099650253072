<template>
  <v-row>
    <v-col col="12">
      <v-data-table
        disable-sort
        :items="sensors"
        :headers="headers"
        :items-per-page="200"
        :height="'75vh'"
        no-data-text=""
        no-results-text=""
        :loading="sensorsLoading"
        @click:row="navigateToSensor"
        :loading-text="'Loading sensors...'"
        :search="search"
      >
        <template v-slot:top>
          <v-row dense align="end" class="ma-2">
            <v-col cols="12" sm="4" lg="1">
              <v-btn color="success" tile block @click="loadSensors"
                ><v-icon>mdi-refresh</v-icon>Refresh</v-btn
              >
            </v-col>
            <v-col cols="12" sm="8" lg="11">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.lastValue="{ item }">
          <div v-if="item.lastValue !== null && item.lastValue != undefined">
            {{ item.lastValue | round }} -
            {{ item.lastValueTimestampUtc | formatDate }}
          </div>
        </template>
        <template v-slot:item.online="{ item }">
          <div v-if="item.online">
            <v-icon color="green"> mdi-broadcast </v-icon>
            Online
          </div>
          <div v-if="!item.online">
            <v-icon color="red"> mdi-broadcast </v-icon>
            Offline
          </div>
        </template>
        <template v-slot:loading> </template>
        <template v-slot:progress> Loading overlay </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      headers: [
        { text: "Status", width: 150, value: "online" },
        { text: "Name", value: "name" },
        { text: "Type", value: "sensorType" },
        { text: "Last Value", value: "lastValue" },
      ],
      search: "",
    };
  },
  async created() {
    await this.loadSensors();
  },
  methods: {
    ...mapActions("sensors", ["getSensorsAsync"]),
    async loadSensors() {
      await this.getSensorsAsync();
    },
    navigateToSensor({ id }) {
      this.$router.push({
        path: `sensors/${id}`,
      });
    },
  },
  computed: {
    ...mapState("sensors", ["sensors", "sensorsLoading"]),
  },
};
</script>
