<template>
  <div>
    <v-sheet>
      <v-row dense class="mx-2" align="stretch">
        <v-col cols="12" sm="12" md="2" lg="1">
          <v-btn
            class="success"
            tile
            :disabled="readingsLoading"
            outlined
            block
            @click="getReadings"
            ><v-icon>mdi-refresh</v-icon>Refresh</v-btn
          >
        </v-col>
        <v-col cols="12" lg="2">
          <v-select
            v-model="selectedRange"
            :disabled="readingsLoading"
            @change="selectedRangeChange"
            :hide-details="true"
            item-text="text"
            item-value="value"
            :items="ranges"
            label="Range"
            return-object
            outlined
            dense
          >
          </v-select>
        </v-col>
        <v-col cols="12" lg="2">
          <v-select
            v-model="selectedGranualityOption"
            :disabled="readingsLoading"
            :hide-details="true"
            item-text="text"
            item-value="value"
            :items="selectedRange.granualityOptions"
            label="Granuality (minutes)"
            outlined
            dense
          >
          </v-select>
        </v-col>
        <!-- <v-col cols="12" sm="12" md="3" lg="6">
          <RangePicker
            :start.sync="start"
            :end.sync="end"
            @startChange="(s) => (start = s)"
            @endChange="(e) => (end = e)"
          ></RangePicker>
        </v-col>
        <v-col cols="12" sm="12" md="3" lg="1">
          <v-select
            v-model="granualitySelected"
            :disabled="readingsLoading"
            :hide-details="true"
            item-text="text"
            item-value="value"
            :items="granuality"
            label="Granuality"
            return-object
            outlined
            dense
          >
          </v-select>
        </v-col> -->
        <v-col cols="12" sm="12" md="2" lg="1">
          <v-btn
            :disabled="readingsLoading"
            outlined
            block
            @click="exportReadings"
            height="100%"
            >Export</v-btn
          >
        </v-col>
      </v-row>
    </v-sheet>
    <v-row dense>
      <v-col cols="12">
        <v-sheet height="650">
          <LineChart v-if="!readingsLoading" :values="readingsFormatted" />
          <v-overlay :value="readingsLoading">
            <v-progress-circular
              :size="250"
              :width="10"
              color="primary"
              indeterminate
            >
              <span>Loading Readings...</span>
            </v-progress-circular>
          </v-overlay>
        </v-sheet></v-col
      >
    </v-row>
  </div>
</template>

<script>
import LineChart from "../../../components/charts/linechart.vue";
// import RangePicker from "../../../components/range-picker.vue";
import { mapActions, mapState } from "vuex";
import moment from "moment";
export default {
  props: ["id"],
  data() {
    return {
      start: moment().startOf("hour").subtract(23, "hour").format(),
      end: moment().startOf("hour").add(1, "hour").format(),
      granuality: [
        { text: "None", value: null },
        { text: "1 Minute", value: 1 },
        { text: "5 Minutes", value: 5 },
        { text: "15 Minutes", value: 15 },
        { text: "30 Minutes ", value: 30 },
        { text: "60 Minutes", value: 60 },
      ],
      granualitySelected: { text: "15 Minutes", value: 15 },
      ranges: [
        {
          text: "Last Hour",
          value: 60,
          granualityOptions: [1, 5, 15, 30, 60],
        },
        {
          text: "Last 12 Hours",
          value: 720,
          granualityOptions: [1, 5, 15, 30, 60],
        },
        {
          text: "Last Day",
          value: 1440,
          granualityOptions: [1, 5, 15, 30, 60],
        },
        { text: "Last Week", value: 10080, granualityOptions: [15, 30, 60] },
        { text: "Last Month", value: 43200, granualityOptions: [30, 60] },
        { text: "Last 3 Months", value: 129600, granualityOptions: [60] },
      ],
      selectedRange: {
        text: "Last Day",
        value: 1440,
        granualityOptions: [5, 15, 30, 60],
      },
      selectedGranualityOption: 15,
    };
  },
  async created() {
    await this.getReadings();
  },
  methods: {
    ...mapActions("sensors", ["getReadingsAsync"]),
    async getReadings() {
      // await this.getReadingsAsync({
      //   id: this.id,
      //   from: new Date(this.start).toISOString(),
      //   to: new Date(this.end).toISOString(),
      //   granuality: this.granualitySelected.value || "",
      // });
      await this.getReadingsAsync({
        id: this.id,
        from: moment()
          .subtract(this.selectedRange.value, "minute")
          .toISOString(),
        to: moment().toISOString(),
        granuality:
          this.selectedGranualityOption !== "None"
            ? this.selectedGranualityOption
            : "",
      });
    },
    exportReadings() {
      let data = [];
      if (this.selectedGranualityOption !== "None") {
        const head = ["time", "avg", "min", "max"];
        data = this.readings.map((r) => [r.timestamp, r.avg, r.min, r.max]);
        data.unshift(head);
      } else {
        const head = ["time", "reading"];
        data = this.readings.map((r) => [r.timestamp, r.value]);
        data.unshift(head);
      }

      const csv = data
        .map(
          (row) =>
            row
              .map(String) // convert every value to String
              .map((v) => v.replaceAll('"', '""')) // escape double colons
              .map((v) => `"${v}"`) // quote it
              .join(",") // comma-separated
        )
        .join("\r\n");

      this.downloadBlob(csv, "export.csv", "text/csv;charset=utf-8;");
    },
    downloadBlob(content, filename, contentType) {
      const blob = new Blob([content], { type: contentType });
      const url = URL.createObjectURL(blob);
      const pom = document.createElement("a");
      pom.href = url;
      pom.setAttribute("download", filename);
      pom.click();
    },
    // ********************
    selectedRangeChange({ granualityOptions }) {
      if (this.selectedGranualityOption != granualityOptions[0]) {
        this.selectedGranualityOption = granualityOptions[0];
      }
    },
  },
  computed: {
    ...mapState("sensors", ["readings", "readingsLoading"]),
    readingsFormatted() {
      const val = this.readings.map((a) => ({ x: a.timestamp, y: a.value }));
      const avg = this.readings.map((a) => ({ x: a.timestamp, y: a.avg }));
      const min = this.readings.map((a) => ({ x: a.timestamp, y: a.min }));
      const max = this.readings.map((a) => ({ x: a.timestamp, y: a.max }));

      let chartData = [];

      if (val.filter((v) => v.y).length > 0) {
        console.warn(val);
        chartData.push({ name: "value", data: val });
      }

      if (avg.filter((v) => v.y).length > 0) {
        chartData.push({ name: "Average", data: avg });
        chartData.push({ name: "Minimum", data: min });
        chartData.push({ name: "Maximum", data: max });
      }

      return chartData;
    },
  },
  components: {
    LineChart,
    // RangePicker,
  },
};
</script>
