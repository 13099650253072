<template>
  <!-- <div>
    <div>{{ value }}</div>
    <div>{{ state }}</div>
    <div>{{ minRange }}</div>
    <div>{{ maxRange }}</div>
  </div>-->
  <apexchart
    ref="gauge"
    type="radialBar"
    width="100%"
    height="325"
    :options="options"
    :series="series"
  ></apexchart>
</template>

<script>
export default {
  props: {
    value: {
      require: false,
      default: undefined,
    },
    state: {
      require: false,
      default: undefined,
    },
    minRange: {
      require: true,
      type: Number,
    },
    maxRange: {
      require: true,
      type: Number,
    },
  },
  data() {
    const self = this;
    return {
      options: {
        chart: {
          type: "radialBar",
          offsetY: -10,
          sparkline: {
            enabled: true,
          },
          width: "100%",
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                offsetY: -2,
                fontSize: "24px",
                color: "#ffffff",
                /* eslint-disable */
                formatter: function (val) {
                  return self.value;
                },
              },
            },
          },
        },
        grid: {
          padding: {
            top: -10,
          },
        },
        fill: {
          opacity: 1.5,
          colors: [this.getColor()],
          type: "solid",
        },
      },
    };
  },
  methods: {
    getColor() {
      const red = "#F44336";
      const yellow = "#FFEB3B";
      const blue = "#03A9F4";
      const green = "#8BC34A";

      if (!this.state) return green;

      if (this.state === "critical") return red;
      if (this.state === "warning") return yellow;
      if (this.state === "informational") return blue;

      return green;
    },
    getValue() {
      return this.value;
    },
  },
  computed: {
    series() {
      let series = [];
      let range = Math.abs(this.maxRange - this.minRange);
      let scale = 100 / range;
      let value = scale * Math.abs(this.minRange - this.value);
      series.push(value.toFixed(2));

      return series;
    },
    color() {
      const red = "#F44336";
      const yellow = "#FFEB3B";
      const blue = "#03A9F4";
      const green = "#8BC34A";

      if (!this.state) return green;

      if (this.state === "critical") return red;
      if (this.state === "warning") return yellow;
      if (this.state === "informational") return blue;

      return green;
    },
  },
  watch: {
    state() {
      this.options = { fill: { colors: [this.getColor()] } };
    },
  },
};
</script>
