import { error, success } from "../../helpers/notifications";
import http from "../../services/http";

const initialState = {
  devices: [],
  devicesLoading: false,
  device: {
    name: "",
    description: "",
    online: false,
    statusLastUpdatedUtc: "",
    settings: {},
  },
  deviceLoading: false,
};

const state = { ...initialState };

const actions = {
  async getDevicesAsync({ commit }) {
    try {
      commit("setDevicesLoading", true);
      const { data } = await http("devices");
      commit("setDevices", data);
    } catch (e) {
      commit("setDevices", []);
      error("Error", `Failed to load devices. ${e.message}`);
    } finally {
      commit("setDevicesLoading", false);
    }
  },
  async getDeviceAsync({ commit }, id) {
    try {
      commit("setDeviceLoading", true);
      const { data } = await http("devices/" + id);
      commit("setDevice", data);
    } catch (e) {
      error("Error", `Failed to load device.`);
    } finally {
      commit("setDeviceLoading", false);
    }
  },
  async updateDeviceInformationAsync(
    { commit, state },
    { deviceInformation, id }
  ) {
    try {
      commit("setDeviceLoading", true);
      await http.put("devices/" + id, deviceInformation);
      const device = state.device;
      device.name = deviceInformation.name;
      commit("setDevice", device);
      success("Success", `${deviceInformation.name} device updated.`);
    } catch (e) {
      error(
        "Error",
        `Failed to update ${deviceInformation.name} device. ${e.error}`
      );
    } finally {
      commit("setDeviceLoading", false);
    }
  },
  async updateDeviceSettingsAsync({ commit, state }, { id, settings }) {
    try {
      commit("setDeviceLoading", true);
      await http.put("devices/" + id + "/settings", {
        scanInterval: 0,
        settings: settings,
      });
      const device = state.device;
      device.settings = settings;
      commit("setDevice", device);
      success("Success", `Device settings updated.`);
    } catch (e) {
      error("Error", `Failed to update device settings. ${e.error}`);
    } finally {
      commit("setDeviceLoading", false);
    }
  },
  async invokeOrCancelCommandAsync({ commit, state }, { id, command, status }) {
    try {
      commit("setDeviceLoading", true);
      await http.put("devices/" + id + "/commands", {
        commandName: command,
        commandParameter: status,
      });
      const device = state.device;
      device.systemCommands[command] = status;
      commit("setDevice", device);
      success("Success", `Device command invoked.`);
    } catch (e) {
      error("Error", `Failed to update invoke device command. ${e.error}`);
    } finally {
      commit("setDeviceLoading", false);
    }
  },
  resetState({ commit }) {
    commit("setIntiialState");
  },
};

const mutations = {
  setDevices: (state, devices) => (state.devices = devices),
  setDevicesLoading: (state, loading) => (state.devicesLoading = loading),
  setDevice: (state, device) => (state.device = device),
  setDeviceLoading: (state, loading) => (state.deviceLoading = loading),
  setIntiialState: (state) => Object.assign(state, initialState),
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
